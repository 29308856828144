import React from 'react';
import { Footer } from 'flowbite-react';
import { LocateIcon, MailIcon, PhoneIcon } from 'src/assets/icons/icons';

export default function FooterModel() {
    return (
        <Footer container={true}>
            <div className="w-full">
                <div className="grid w-full justify-between sm:flex sm:justify-between md:flex md:grid-cols-1">
                    <div>
                        <Footer.Brand href="https://v-cons.com" name="VCONS" />
                        <div className="mt-4 ml-4 text-left text-[14px]">
                            <a
                                target="_blank"
                                className="mt-1 flex "
                                href="https://www.google.com/maps/place/Thanh+H%C3%A0,+Nam+S%C6%A1n,+S%C3%B3c+S%C6%A1n,+Hanoi,+Vietnam/@21.2965916,105.8079099,16z/data=!3m1!4b1!4m5!3m4!1s0x31351d31386ffbe5:0x9dfb1e7136a26f78!8m2!3d21.2964874!4d105.8128851"
                                rel="noreferrer"
                            >
                                {<LocateIcon className="mr-2" />} Thanh Hà, Nam Sơn, Sóc Sơn, Hà Nội
                            </a>
                            <div className="mt-3 flex text-center">
                                {<PhoneIcon className="ml-1 mr-2" />} +84 123456789
                            </div>
                            <div className="mt-3 flex">
                                {<MailIcon className="ml-1 mr-2" />} admin@gmail.com
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-8 max-[640px]:hidden sm:mt-4 sm:grid-cols-2 sm:gap-6">
                        <div className="text-left">
                            <Footer.Title title="Về chúng tôi" />
                            <Footer.LinkGroup col={true}>
                                <Footer.Link href="#">Giới thiệu</Footer.Link>
                                <Footer.Link href="#">Hồ sơ công ty</Footer.Link>
                            </Footer.LinkGroup>
                        </div>
                        <div className="text-left">
                            <Footer.Title title="Dịch vụ" />
                            <Footer.LinkGroup col={true}>
                                <Footer.Link href="#">Bản vẽ kết cấu thép</Footer.Link>
                                <Footer.Link href="#">Bóc tách vật tư</Footer.Link>
                                <Footer.Link href="#">Dự toán công trình</Footer.Link>
                            </Footer.LinkGroup>
                        </div>
                    </div>
                </div>
                <Footer.Divider />
                <div className="w-full sm:flex sm:items-center sm:justify-between">
                    <Footer.Copyright href="#" by="VCONS" year={2023} />
                    <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center"></div>
                </div>
            </div>
        </Footer>
    );
}
