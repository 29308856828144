import React, { useState } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import {
    PDF1_1,
    PDF1_2,
    PDF1_3,
    PDF1_4,
    PDF1_5,
    PDF1_6,
    PDF1_7,
    PDF2_1,
    PDF2_2,
    PDF2_3,
    PDF2_4,
    PDF3_1,
    PDF3_2,
    PDF3_3,
} from 'src/assets/pdf';
import { Dropdown } from 'flowbite-react';

export default function ProductModule() {
    const [pdfFile, setPdfFile] = useState('');

    const handleSetFile = (file) => setPdfFile(file);

    const productList = [
        {
            title: '原寸資料',
            items: [
                {
                    title: '①単管コア加工指示書',
                    filePath: PDF1_1,
                },
                {
                    title: '②シャフト加工指示書',
                    filePath: PDF1_2,
                },
                {
                    title: '③ブラケット加工指示書',
                    filePath: PDF1_3,
                },
                {
                    title: '④大梁加工指示書',
                    filePath: PDF1_4,
                },
                {
                    title: '⑤小梁加工指示書',
                    filePath: PDF1_5,
                },
                {
                    title: '⑥小物リスト',
                    filePath: PDF1_6,
                },
                {
                    title: '⑥柱付　切り板',
                    filePath: PDF1_7,
                },
            ],
        },
        {
            title: '基準図',
            items: [
                {
                    title: '一般図',
                    filePath: PDF2_1,
                },
                {
                    title: '付帯基準図',
                    filePath: PDF2_2,
                },
                {
                    title: '溶接基準。社内基準図',
                    filePath: PDF2_3,
                },
                {
                    title: '継手基準図',
                    filePath: PDF2_4,
                },
            ],
        },
        {
            title: '詳細図',
            items: [
                {
                    title: '大梁詳細図',
                    filePath: PDF3_1,
                },
                {
                    title: '本柱詳細図',
                    filePath: PDF3_2,
                },
                {
                    title: '間柱、小梁詳細図',
                    filePath: PDF3_3,
                },
            ],
        },
    ];
    return (
        <div className={`${pdfFile !== '' ? 'h-[80vh]' : ''}`}>
            <div className="flex justify-between border-b pb-5">
                {productList.map((item, index) => (
                    <Dropdown label={item.title} key={index}>
                        {item.items.map((file, fileIndex) => (
                            <Dropdown.Item
                                key={fileIndex}
                                onClick={(e) => {
                                    handleSetFile(file.filePath);
                                }}
                            >
                                {file.title}
                            </Dropdown.Item>
                        ))}
                    </Dropdown>
                ))}
            </div>
            {pdfFile !== '' && (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
                    <div className="h-[75vh]">
                        <Viewer fileUrl={pdfFile} />
                    </div>
                </Worker>
            )}
        </div>
    );
}
