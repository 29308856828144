import React from 'react';
import { Carousel } from 'flowbite-react';
import { i1, i2, i3 } from '../../assets/img/index';
import Subnav from '../Subnav';
import { LocateIcon, PhoneIcon } from 'src/assets/icons/icons';

export default function Carouseles() {
    const carouselesImages = [
        { img: i1, alt: '...' },
        { img: i2, alt: '...' },
        { img: i3, alt: '...' },
    ];

    return (
        <div className="relative" style={{ height: window.innerHeight }}>
            <Carousel slideInterval={3500} className="brightness-[.7]">
                {carouselesImages.map((item, index) => (
                    <img
                        className="h-full object-cover"
                        key={index}
                        src={item.img}
                        alt={item.alt}
                    />
                ))}
            </Carousel>
            <Subnav />
            <div className="absolute top-0 left-0 right-0 flex h-10 items-center justify-between bg-[rgba(49,49,49,0.25)] px-7 text-left text-[14px] font-medium text-white max-[485px]:hidden">
                <span className="flex items-center px-4">
                    <div className="mr-2">{<LocateIcon />}</div> Thanh Hà, Nam Sơn, Sóc Sơn, Hà Nội
                    <span className="mx-3">|</span>
                    <div className="flex">{<PhoneIcon className="mr-2" />} +84 123456789</div>
                </span>
                <div className="flex items-center">
                    <p className="mr-2 text-[12px]">Ngôn Ngữ</p>
                    <div className="switch">
                        <input
                            id="language-toggle"
                            className="check-toggle check-toggle-round-flat"
                            type="checkbox"
                        />
                        <label htmlFor="language-toggle"></label>
                        <span className="on">VIE</span>
                        <span className="off">JPN</span>
                    </div>
                </div>
            </div>
            <div className="absolute top-[50%] left-[50%] flex translate-x-[-50%] translate-y-[-50%] items-center justify-center">
                <div className="text-[60px] font-semibold text-[white]">
                    Chào mừng đến với công ty TNHH thiết kế và tư vấn xây dựng VCONS
                </div>
            </div>
        </div>
    );
}
